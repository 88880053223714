// Enables or disables spectating in rooms.
export var spectateEnabled = false;

// Enables or disables custom fast-entry when joining the room.
export var fastEntry = true;

// Teleporter hit color.
export var teleporterColor = "#302a7e";

// Allow link sharing in-app.
export var sharingEnabled = true;

// Allow video sharing button and features.
export var videoSharingEnabled = true;