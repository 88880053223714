// URL for api requests to goto.
//export var serverURL = "http://localhost/vision-front-end/";
export var serverURL = "https://alternatestates.vision/";

// URL for spoke.
//export var spokeURL = "https://localhost:9090/";
export var spokeURL = "https://dashboard.alternatestates.vision/spoke/";

// S3 Bucket for accessing the converter models etc.
export var s3bucketURL = "https://vision-web-live-bucket.s3.eu-west-2.amazonaws.com/";

// Toggle Debug.Logs in console.
export var showDebug = false; 

// 1 = Just surface level debug, 2 = more verbose, 3 = everything.
export var debugLevel = 3;

// Authentication for when the user enters the room etc.
export var authenticationEnabled = true;